
import { Component, Vue, Prop } from "vue-property-decorator";
import PostCard from "@/components/PostCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";

const MAX_VISIBLE_POSTS_COLUMN = 6;

@Component({
  components: { PostCard, BaseButton },
})
export default class PostPreviewColumn extends Vue {
  @Prop() readonly items?: Array<BrainLibraryDTO>;
  @Prop({ default: "" }) goToPathOnReadMoreClick?: string;

  onReadMoreClick(): void {
    if (this.goToPathOnReadMoreClick) {
      this.$router.push({
        path: this.goToPathOnReadMoreClick,
      });
    }
  }

  get visiblePosts(): Array<BrainLibraryDTO> {
    return this.items ? this.items.slice(0, MAX_VISIBLE_POSTS_COLUMN) : [];
  }
}
